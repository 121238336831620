import React, { useState } from "react";
import Stat from "../components/Stat";
import MainNavigation from "../components/MainNavigation";
import { Base, Text } from "../styles";
import ThemeProvider from "../styles/provider";
import CTAButton from "../components/CTAButton";
import ButtonCard from "../components/ButtonCard";
import styled from "styled-components";
const VariantName = (props) => /* @__PURE__ */ React.createElement(Text, {
  borderBottom: "2px solid",
  display: "block",
  variant: "small",
  mb: "10px"
}, props.children);
const SectionTitle = (props) => /* @__PURE__ */ React.createElement(Text, {
  as: "h2",
  variant: "x-large",
  mb: "20px"
}, props.children);
const BaseLoadingComponent = ({
  numLoaded,
  total,
  className
}) => {
  return /* @__PURE__ */ React.createElement("span", {
    className
  }, "Loaded ", numLoaded, " of ", total);
};
const DefaultLoadingComponent = styled(BaseLoadingComponent)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ numLoaded, total }) => numLoaded === total ? "-1" : "2"}};
`;
export default () => {
  const [loadProgress, setLoadProgress] = useState(null);
  return /* @__PURE__ */ React.createElement(ThemeProvider, null, /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    p: "20px"
  }, /* @__PURE__ */ React.createElement(SectionTitle, null, "Typography"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "ultra",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "headline-ultra"), "FX1000V EFI"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "xx-large",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "xx-large"), "38.5"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "x-large",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "x-large"), "FX1000V EFI"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "large",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "large"), "38.5 HP 2CYL (large)"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "medium",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "medium"), "Discover what sets us apart through an immersive gallery of the features and technologies that makes an engine a Kawasaki Engine."), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "small",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "small"), "Get work done in a wide variety of terrains and temperatures with our Electronic Fuel Injection EFI technology. Our most powerful EFI engine offers the performance and the reliability that you can build a reputation on."), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "x-small",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "x-small"), "Get work done in a wide variety of terrains and temperatures with our Electronic Fuel Injection EFI technology. Our most powerful EFI engine offers the performance and the reliability that you can build a reputation on."), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "x-large-bold",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "x-large-bold"), "FX1000V EFI"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "large-bold",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "large-bold"), "38.5 HP 2CYL (large)"), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "medium-bold",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "medium-bold"), "Discover what sets us apart through an immersive gallery of the features and technologies that makes an engine a Kawasaki Engine."), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "small-bold",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "small-bold"), "Get work done in a wide variety of terrains and temperatures with our Electronic Fuel Injection EFI technology. Our most powerful EFI engine offers the performance and the reliability that you can build a reputation on."), /* @__PURE__ */ React.createElement(Text, {
    as: "h3",
    variant: "xxx-small-bold",
    mb: "20px"
  }, /* @__PURE__ */ React.createElement(VariantName, null, "xxx-small-bold"), "Get work done in a wide variety of terrains and temperatures with our Electronic Fuel Injection EFI technology. Our most powerful EFI engine offers the performance and the reliability that you can build a reputation on."), /* @__PURE__ */ React.createElement(SectionTitle, null, "Components"), /* @__PURE__ */ React.createElement("pre", null, /* @__PURE__ */ React.createElement("code", null, "<Stat />")), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    maxWidth: "300px",
    bg: "black",
    p: "20px"
  }, /* @__PURE__ */ React.createElement(Stat, {
    stat: "38.5",
    label: "HORSEPOWER"
  })), /* @__PURE__ */ React.createElement("pre", null, /* @__PURE__ */ React.createElement("code", null, "<CTAButton />")), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    bg: "black",
    p: "20px"
  }, /* @__PURE__ */ React.createElement(CTAButton, {
    link: {
      url: "#",
      text: 'Default CTA Button Styles, isFontResponsive="true" & backgroundStyle="red"'
    }
  }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(CTAButton, {
    backgroundStyle: "white-outline",
    link: {
      url: "#",
      text: 'backgroundStyle="white-outline"'
    }
  }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(CTAButton, {
    isFontResponsive: false,
    link: {
      url: "#",
      text: 'isFontResponsive="false"'
    }
  }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(CTAButton, {
    isFontResponsive: false,
    backgroundStyle: "white-outline",
    link: {
      url: "#",
      text: 'isFontResponsive="false" backgroundStyle="white-outline"'
    }
  })), /* @__PURE__ */ React.createElement("pre", null, /* @__PURE__ */ React.createElement("code", null, "<ButtonCard copy link? headline? color? />")), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    bg: "black",
    p: "20px"
  }, /* @__PURE__ */ React.createElement(ButtonCard, {
    headline: "The FX Series",
    copy: "The commercial power and performance you need to do your job the right way.",
    link: {
      url: "#",
      text: "See the full line"
    }
  })), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    bg: "white",
    p: "20px"
  }, /* @__PURE__ */ React.createElement(ButtonCard, {
    headline: "The FX Series",
    copy: "The commercial power and performance you need to do your job the right way.",
    link: {
      url: "#",
      text: "See the full line"
    },
    color: "black"
  })), /* @__PURE__ */ React.createElement(VariantName, null, "Main Navigation"), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    bg: "black",
    p: "20px"
  }, /* @__PURE__ */ React.createElement(MainNavigation, {
    items: [
      {
        text: "Engines",
        url: "/engines/",
        selected: true
      },
      {
        text: "Genuine Parts",
        url: "/genuine-parts/",
        selected: false
      },
      {
        text: "Testing",
        url: "/testing/",
        selected: false
      }
    ]
  }))));
};

import React from "react";
import { Base, Text } from "../styles";
import theme from "../styles/theme";
import styled from "styled-components";
import CTAButton from "./CTAButton";
const optionalTextShadow = "";
const Headline = styled(Text)`
  text-transform: uppercase;
  ${optionalTextShadow}
`;
const Copy = styled(Text)`
  padding: 8px 0 16px;
  margin-bottom: 16px;
  ${optionalTextShadow}
`;
const ButtonCardWrapper = styled(Base)``;
const defaultButtonCardProps = {
  color: theme.colors.white
};
const ButtonCard = ({ headline, copy, link, color, target }) => {
  return /* @__PURE__ */ React.createElement(ButtonCardWrapper, {
    as: "div",
    color
  }, headline && /* @__PURE__ */ React.createElement(Headline, {
    as: "h2",
    variant: ["large-bold", "large-bold", "x-large-bold"]
  }, headline), /* @__PURE__ */ React.createElement(Copy, {
    as: "p",
    variant: ["x-small", "x-small", "small"]
  }, copy), link && /* @__PURE__ */ React.createElement(CTAButton, {
    link,
    target
  }));
};
ButtonCard.defaultProps = defaultButtonCardProps;
export default ButtonCard;
